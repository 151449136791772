import { render, staticRenderFns } from "./WaterWork.vue?vue&type=template&id=223666e2&scoped=true"
import script from "./WaterWork.vue?vue&type=script&lang=js"
export * from "./WaterWork.vue?vue&type=script&lang=js"
import style0 from "./WaterWork.vue?vue&type=style&index=0&id=223666e2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "223666e2",
  null
  
)

export default component.exports